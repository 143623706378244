.footer-container {
  margin: 20px;
  /* position: absolute; */
  /* width: 100%; */
  /* bottom: 0; */
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--text-color-1);
}

.footer-col {
  margin: 5px;
  color: var(--text-color-1);
}

.footer-col a {
  color: var(--text-color-1);
}
