.App {
  text-align: center;
  /* background-image: url("./assets/abstract_orange_black.svg"); */
  background-position: left top, left bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: black;
  /* padding: 2rem 7rem; */
  /* background-size: cover; */
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  min-height: 100vh;
}

.main-box {
  font-family: "Roboto";
  background-color: #1e1e1eed;
  box-shadow: var(--shadow-param-1) var(--shadow-color-1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 50px auto;
  width: 80vw;
}
.projects {
  flex-grow: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.project-logo {
  padding: 3vh;
}

/* 
--bg-color-1: #1e1e1eed;
--bg-color-2: #393939ed; */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.smallbox {
  width: 40%;
}

.box-container {
  display: flex;
  justify-content: space-evenly;
}

.container {
  max-width: 500px;
  min-width: 60%;
  margin: 50px auto;
}

h1 {
  text-align: center;
}
.progressbar-container {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.progressbar-complete {
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: var(--bg-color-3);
  border-radius: 10px;
  animation: g 2500ms infinite ease-in-out;
  z-index: 2;
}
.progressbar-liquid {
  z-index: 1;
  width: 70px;
  height: 70px;
  animation: g 2500ms infinite ease-in-out,
    r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: var(--bg-color-3);
  border-radius: 40%;
}
.progress {
  z-index: 2;
}

.container2 {
  max-width: 500px;
  min-width: 60%;
  margin: 50px auto;
}

h1 {
  text-align: center;
}
.progressbar-container2 {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.progressbar-complete2 {
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: var(--bg-color-3);
  border-radius: 10px;
  animation: g 2500ms infinite ease-in-out;
  z-index: 2;
}
.progressbar-liquid2 {
  z-index: 1;
  width: 70px;
  height: 70px;
  animation: g 2500ms infinite ease-in-out,
    r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: var(--bg-color-3);
  border-radius: 40%;
}
.progress2 {
  z-index: 2;
}

@keyframes g {
  0% {
    background-color: var(--bg-color-3);
  }
  50% {
    background-color: #00a5ff;
  }
  100% {
    background-color: var(--bg-color-3);
  }
}

@keyframes r {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.claim-box {
  padding: 5px;
}

.topbar-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 8vh;
  padding: 2rem 7rem;
}

img {
  width: 16rem;
  height: auto;
}

p.thick {
  font-family: "SourceSansPro-Semibold";
  text-align: center;
  margin: 0px;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit; /* This will make the link take the color of its parent element */
  cursor: pointer;
}

.topbar-box-left {
  justify-self: start;
}
.topbar-box-center {
  justify-content: center;
  align-self: center;
  font-size: x-large;
}

.topbar-box-right {
  justify-self: end;
}

@media (max-width: 768px) {
  .Main {
    padding: 1rem;
  }

  .topbar-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 0;
  }

  .topbar-box-left {
    grid-row: 1;
    align-items: center;
    justify-self: center;
  }

  .topbar-box-center {
    grid-row: 2;
  }

  .topbar-box-right {
    grid-row: 3;
    align-items: center;
    justify-self: center;
  }
}

.ReactModal__Overlay {
  z-index: 5;
}

.ReactModal__Overlay--after-open {
  z-index: 5;
}

.loader1 {
  display: inline-flex;
  font-size: 0px;
  padding: 0px;
}
.loader1 span {
  vertical-align: middle;
  border-radius: 100%;

  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px 2px;
  -webkit-animation: loader1 0.8s linear infinite alternate;
  animation: loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(0, 167, 254, 0.6);
}
.loader1 span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(0, 167, 254, 0.8);
}
.loader1 span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  background: rgba(0, 167, 254, 1);
}
.loader1 span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(0, 167, 254, 0.8);
}
.loader1 span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(0, 167, 254, 0.4);
}

@keyframes loader1 {
  from {
    transform: scale(0, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes loader1 {
  from {
    -webkit-transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
  }
}

/* 
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

body {
  background-color: #2b2b2b;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.container {
  max-width: 500px;
  margin: 50px auto;

  h1 {
    text-align: center;
  }
  .progressbar-container {
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .progressbar-complete {
      position: absolute;
      left: 0;
      top: 0px;
      height: 100%;
      background-color: var(--bg-color-3);
      border-radius: 10px;
      animation: g 2500ms infinite ease-in-out;
      z-index: 2;
      .progressbar-liquid {
        z-index: 1;
        width: 70px;
        height: 70px;
        animation: g 2500ms infinite ease-in-out,
          r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
        position: absolute;
        right: -5px;
        top: -10px;
        background-color: var(--bg-color-3);
        border-radius: 40%;
      }
    }
    .progress {
      z-index: 2;
    }
  }
}

@keyframes g {
  0% {
    background-color: var(--bg-color-3);
  }
  50% {
    background-color: #607e9e;
  }
  100% {
    background-color: var(--bg-color-3);
  }
}

@keyframes r {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
} */
