.connect-modal {
  /* max-width: 95ch;
  margin: auto;
  position: absolute; */
  /* inset: 40px; */
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 2rem;
  /* max-height: 100vh; */
  overflow-y: auto;
  background-color: var(--bg-color-1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.wallet-options-container {
  display: grid;
  padding-top: 1rem;
  gap: 1.25rem;
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 38rem) {
  .wallet-options-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.wallet-option {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--bg-color-3);
  background-color: transparent;
  border-width: 0.1rem;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  transition: all 300ms ease 0s;
}

.wallet-option > span {
  font-size: 1.2rem;
}

.wallet-option > img {
  width: 5rem;
  height: 5rem;
  padding: 0.5rem;
}

.wallet-option:hover {
  border-color: var(--bg-color-3);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
